/**
 * This mixin adds vuex store related logic
 */

/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    methods: {
        subscribe(type, callback) {
            this.$store.subscribe((mutation, state) => {
                if (mutation.type === type)
                    callback(state);
            });
        },

        dispatch(action, payload) {
            this.$store.dispatch(action, payload);
        },
    },
})

/* ----------------------------------------
    Export
---------------------------------------- */

export class StoreMixin extends Vue {
    
}