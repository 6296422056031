
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapState, mapMutations, mapGetters } from 'vuex';
import { handle429 } from '@util/util.ts';
import { app } from '@config';
import HeartInactiveSvg from '@svg/HeartInactive.vue';
import HeartActiveSvg from '@svg/HeartActive.vue';
import CrossSvg from '@svg/Cross.vue';
import axios from 'axios';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    props: ['type', 'id', 'inline', 'with-text'],
    components: {
        HeartInactiveSvg,
        HeartActiveSvg,
        CrossSvg
    },
    data() {
        return {
            isHovered: false,
            isSelected: false,
            isWaiting: false
        };
    },
    computed: {
        ...mapState(['activeHearts', 'reloadMyNightButton']),
        ...mapGetters({ isLoggedIn: 'user/isLoggedIn' })
    },
    watch: {
        reloadMyNightButton: function() {
            this.setInitialState();
        }
    },
    methods: {
        ...mapMutations(['removeFromActiveHearts', 'addToActiveHearts']),
        setInitialState() {
            let indOf = this.activeHearts[this.type].indexOf(this.id);
            if (indOf >= 0) {
                this.isSelected = true;
            } else {
                this.isSelected = false;
            }
        },
        addOrRmFromDB(addRm) {
            this.isWaiting = true;
            const instance = axios.create({
                baseURL: app.api.auth,
                timeout: 2500,
                headers: {
                    Authorization: 'Bearer ' + this.user.token
                }
            });

            let typeSingular = this.type.substring(0, this.type.length - 1);
            instance
                .get(`/route/${addRm}-${typeSingular}/${this.id}`)
                .then(response => {
                    this.isWaiting = false;
                    return response.data;
                }).catch(error => {
                    handle429(this, error);
                });
        },
        remove() {
            this.removeFromActiveHearts({ type: this.type, id: this.id });
            this.addOrRmFromDB('remove');
        },
        add() {
            this.addToActiveHearts({ type: this.type, id: this.id });
            this.addOrRmFromDB('add');
        },
        handleClick() {
            if (!this.isLoggedIn) {
                this.$router.push('/login');
            } else {
                this.isSelected && this.remove();
                !this.isSelected && this.add();
                this.isSelected = !this.isSelected;
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.isLoggedIn && this.setInitialState();
        }, 100);
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class MyNightButton extends Vue {}
